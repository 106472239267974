const { location: { host } } = window

const production = {
  ENV: 'production',
  SERVER_URL: 'https://api.viethub.net',
  WEB_URL: 'https://viethub.vn',
  IMAGE_LINK: 'https://worldbasesys.s3.ap-southeast-1.amazonaws.com/vhub/prod',
  ECK: 'Viethub@839P',
  ONE_SIGNAL_APP_ID: '024d2e38-388a-44fb-87bc-8f8148b646f5',
  ONE_SIGNAL_SAFARI_WEB_ID: 'web.onesignal.auto.36762c33-c595-4251-8e66-ea9a822d3713',
}

const uat = {
  ENV: 'uat',
  SERVER_URL: 'https://dev-api.viethub.net',
  WEB_URL: 'https://dev.viethub.net',
  IMAGE_LINK: 'https://worldbasesys.s3.ap-southeast-1.amazonaws.com/vhub/staging',
  ECK: 'Viethub@938U',
  ONE_SIGNAL_APP_ID: '024d2e38-388a-44fb-87bc-8f8148b646f5',
  ONE_SIGNAL_SAFARI_WEB_ID: 'web.onesignal.auto.36762c33-c595-4251-8e66-ea9a822d3713',
}

const dev = {
  ENV: 'dev',
  SERVER_URL: 'https://dev-api.viethub.net',
  WEB_URL: 'https://vhub.io:3888',
  IMAGE_LINK: 'https://worldbasesys.s3.ap-southeast-1.amazonaws.com/vhub/staging',
  ECK: 'Viethub@938U',
  ONE_SIGNAL_APP_ID: '024d2e38-388a-44fb-87bc-8f8148b646f5',
  ONE_SIGNAL_SAFARI_WEB_ID: 'web.onesignal.auto.36762c33-c595-4251-8e66-ea9a822d3713',
}

export const envConfigs = host.indexOf('dev.viethub.net') >= 0 ? uat : (host.indexOf('viethub.vn') >= 0 ? production : dev)
